import React, { Component } from 'react';
//import { Link ,Redirect } from 'react-router-dom';
import { HashRouter, Route, Switch,Link ,Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages views\Base\Forms const Forms = React.lazy(() => import('./views/Base/Forms'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Reseller = React.lazy(() => import('./views/Pages/Register/Reseller'));
const Myregister = React.lazy(() => import('./views/Pages/Register/Myregister'));
const Emailverification = React.lazy(() => import('./views/Pages/Register/Emailverification'));
const Useremailverification = React.lazy(() => import('./views/Pages/Register/Useremailverification'));
const Forgotpassword = React.lazy(() => import('./views/Pages/Register/Forgotpassword'));

const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Adduser = React.lazy(() => import('./views/Base/Forms/Adduser'));
const Edituser = React.lazy(() => import('./views/Base/Forms/Edituser'));
const Listuser = React.lazy(() => import('./views/Base/Forms/Listuser'));
const Keymanage = React.lazy(() => import('./views/Base/Forms/Keymanage'));
const Permission = React.lazy(() => import('./views/Base/Forms/Permission'));

const url = "/" ;

class App extends Component {

  render() {
	  
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
			<Route exact path="/" name="Home" render={props => <Login {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/reseller" name="Reseller Page" render={props => <Reseller {...props}/>} />
			  <Route exact path="/myregister" name="My Register Page" render={props => <Myregister {...props}/>} />
             <Route exact path="/emailverification/:id" name="Email Verification" render={props => <Emailverification {...props}/>} />
    <Route exact path="/useremailverification/:id" name="Email Verification" render={props => <Useremailverification {...props}/>} />         
		<Route exact path="/forgotpassword" name="Forgot Password" render={props => <Forgotpassword {...props}/>} />
			  	  
			  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
				  
	  <Route exact path="/adduser" name="Add User Page" render={props => <DefaultLayout {...props}/>} />
      <Route exact path="/listuser" name="List User Page" render={props => <DefaultLayout {...props}/>} />
      <Route exact path="/listreseller" name="List Reseller" render={props => <DefaultLayout {...props}/>} />
      <Route exact path="/permission" name="User Permission Page" render={props => <DefaultLayout {...props}/>} />        
	  <Route exact path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
    <Route exact path="/docdetails" name="Doc Details" render={props => <DefaultLayout {...props}/>} />
    <Route exact path="/uploaddocs" name="Upload Docs" render={props => <DefaultLayout {...props}/>} />
			<Route exact path="/addnews" name="Addnews" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/listnews" name="Listnews" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/adddms" name="Adddms" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/listdms" name="Listdms" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/cshareg1" name="CSHA Reg 1" render={props => <DefaultLayout {...props}/>} />       
<Route exact path="/category/:id/:name/:sub_cat_name" name="Category" render={props => <DefaultLayout {...props}/>} />
 <Route exact path="/search/:id" name="Search" render={props => <DefaultLayout {...props}/>} />
 <Route exact path="/profile" name="Profile" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/reports" name="Reports" render={props => <DefaultLayout {...props}/>} /> 
<Route exact path="/keymanage" name="keymanage" render={props => <DefaultLayout {...props}/>} />
<Route exact path="/promomanage" name="promomanage" render={props => <DefaultLayout {...props}/>} />  			    
<Route exact path="/cancelaccount" name="Cancelaccount" render={props => <DefaultLayout {...props}/>} />  
<Route exact path="/payment" name="Payment" render={props => <DefaultLayout {...props}/>} />         
<Route exact path="/managepayment/:id" name="Manage Payment" render={props => <DefaultLayout {...props}/>} />       
 <Route exact path="/paymentuser" name="Payment User" render={props => <DefaultLayout {...props}/>} />      
  <Route exact path="/renewalprice/:id" name="Renewal Price" render={props => <DefaultLayout {...props}/>} /> 
  <Route exact path="/rssfeed" name="OSHA News Releases" render={props => <DefaultLayout {...props}/>} /> 
           </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
